import './App.scss'
import Main from '../Main/Main'

function App() { 
  return (
    <div className="App">
      <Main />
    </div>
  )
}

export default App
